.pages-big-title {
  color: black;

  font-weight: 400;
  font-size: 2em;
  text-transform: none;
  line-height: 1.3em;
  margin-right: 10px;
}

.pages-medium-title {
  color: black;
  font-weight: 400;
  font-size: 1em;
  text-transform: none;
  line-height: 1.3em;
  margin-right: 10px;
}

.RowRoleHr {
  margin: 10px !important;
}

.ChangePasswordRecommendation div {
  margin-left: 20px;
}

.text-responsive-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.text-responsive-overflow:hover {
  text-overflow: clip !important;
  white-space: normal !important;
  word-break: break-all !important;
}

.p-main-layout {
  margin: 4px;
}

html {
  background-color: white;
}

.k-drawer-container {
  background-color: white;
}

.DetailCardElementTitle {
  font-weight: bold;
}

.mr-2em {
  margin-right: 0.2em;
}

.mr-6em {
  margin-right: 0.6em;
}

.FormAppBar {
  background-color: #e0e0e2;
  color: black;
  padding: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.CriteriaBullet {
  color: black;
  margin-right: 0.1em;
  font-weight: bold;
}

.k-animation-container-relative {
  display: block !important;
}

.FileActionSticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: "#d4d4d4" !important;

  z-index: 999;

  height: 50px;
}

.GridListTitle {
  color: black;
  font-weight: 400;
  font-size: 2em;
  text-transform: none;
  line-height: 1.3em;
  margin-right: 10px;
}

.NotNormalTest {
  background-color: #ffd166;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
}

.inactive-user-status {
  background-color: red;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
}
.active-user-status {
  background-color: #06d6a0;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
}
.lost-user-status {
  background-color: #ff6358;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
}
.NormalTest {
  background-color: #06d6a0;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
}

.MissingTest {
  background-color: #e0e0e2;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  padding: 3px 15px;
}

.SpanDisabled {
  padding: none !important;
  margin: none !important;
}

.DetailFormHeader {
  background-color: #e0e0e282;
}

.k-tabstrip-items .k-item.k-state-active {
  background-color: #ff6358;
  color: white;
}

.k-tabstrip {
  background-color: #ff635811 !important;
  color: black;
  border-color: black;
}

.subject-id-style {
  font-weight: bolder;
  color: #ff6358;
  font-size: 20px;
}

.SubjectIdRecommendation2 div {
  margin-left: 20px;
}
.SubjectIdRecommendation2 {
  font-weight: bolder;
}

.SubjectIdRecommendation1 {
  font-weight: bolder;
}

.subject-structure-style {
  color: #ff6358;
  font-weight: bolder;
  text-align: center;
  font-size: 30px;
}

.k-loading-mask {
  color: #ff6358;
}

.crf-id-link {
  color: #4970a9;
  cursor: pointer;
}

.k-grid-header .k-header {
  height: 20px !important;
}

.study-title-style {
  font-size: 30px;
  font-weight: bolder;
}

.study-info-detail {
  margin-top: 100px;
  font-size: 30px;
}

.study-all-container {
  background-color: #e0e0e2;
}

.section-locked-container {
  position: absolute;
  top: 50%;
  left: 50%;

  height: 200px;
  margin-left: -100px;
  margin-top: -100px;
}

.no-leave-change {
  text-align: center;
}

.password-recommendation-style > li {
  margin-left: 20px;
}

.full-page-style {
  max-height: calc(100vh - 50px);
  overflow: auto;
  height: calc(100vh - 67px);
}

.users-full-page-style {
  max-height: calc(100vh - 150px);
  overflow: auto;
  height: calc(100vh - 150px);
}
