.k-drawer {
  background-color: white;
  color: black;
}

.k-drawer-item:hover {
  background-color: #e0e0e2;
  color: #ff6358;
}

.k-appbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.k-drawer-item:focus:hover {
  background-color: #ff6358;
  color: white;
}

.k-drawer-push .k-drawer {
  align-self: auto;
}

.k-drawer-items .k-state-selected {
  color: white;
  background-color: #ff6358;
}

.k-drawer-items .k-state-selected:hover {
  color: white;
  background-color: #ff6358;
}

.btn-custom-topbar {
  color: #232b4e;
  background-color: white;
  border: none;
  cursor: pointer;
}

.btn-custom-topbar:hover {
  color: #232b4e;
  background-color: white;
}

.app-topbar-title {
  color: white !important;
  font-size: 20px !important;
}

.topbar-custom {
  max-height: 300px !important;
  background: white;
  color: #232b4e;
  border-bottom: 4px solid #232b4e;
  border-top: 4px solid #232b4e;
}

//SETTINGS DRAWER
.SettingsDrawer .k-drawer-item:hover {
  background-color: #e0e0e2;
  color: #ff6358;
}

.SettingsDrawer .k-drawer-items .k-state-selected {
  background-color: #ff6358;
  color: white;
}

.SettingsDrawer .k-drawer-items .k-state-selected:hover {
  background-color: #ff6358;
  color: white;
}

.SettingsDrawer .expanded-title {
  font-weight: 700;
}

.SettingsDrawer .indent-title > span {
  margin-left: 10px;
  padding: 0px;
}

.SettingsDrawer .main-title {
  font-weight: italic;
  padding: 15px;
}

.SettingsDrawer .drawer-content {
  padding: 4px;
}
