.SaveActionButtons {
  font-size: 17px;
  color: #118ab2 !important;
  cursor: pointer;
}

.EditActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: orange !important;
}
.ViewActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: #ff6358 !important;
}

.DocumentActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: #36437af7 !important;
}

.FeedbackActionButton {
  font-size: 17px;
  cursor: pointer;
  color: #118ab2 !important;
}

.InformedActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: #36437af7 !important;
}

.ApproveActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: #06d6a0 !important;
}

.UploadActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: #232b4e !important;
}

.DeleteActionButtons {
  font-size: 17px;
  cursor: pointer;
  color: red !important;
}

.response-icon-style {
  font-size: 17px;
  cursor: pointer;
  color: #36437af7 !important;
}
//back button
.back-button-style {
  background-color: #323232;
  color: white;
}
.back-button-style:hover {
  background-color: #323232;
  color: white;
}

//cancel button
.cancel-button-style {
  background-color: #323232;
  color: white;
}
.cancel-button-style:hover {
  background-color: #323232;
  color: white;
}

//make the button large
.k-button {
  padding: 4px 12px !important;
}

//dropdown button of the language settings
.LanguageSetting .k-button {
  background-color: #232b4e;
  border-color: #232b4e;
}

.LanguageSetting .k-i-globe {
  color: white;
  font-size: x-large;
}

.float-right {
  float: right;
}

.ActionsButtons {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.GridFilterButton {
  background-color: #118ab2;
  color: white;
}

.GridFilterButton:hover {
  background-color: #118ab2;
}

.app-top-bar-customicons {
  font-size: x-large;
}

.bootstrap-close-button {
  background-color: white !important;
  margin-top: 10px !important;
  font-size: 20px !important;
  opacity: 0.8 !important;
}

// .bootstrap-close-button > image {
//   color: white i !important;
// }
