@import "./login/index";
@import "./button/index";
@import "./sidebar/index";
@import "./drawer/index";
@import "./layout/index";
@import "./inputs/index";
@import "./confirm-alert/index";
@import "./popup/index";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  overflow-x: hidden;
}

.k-grid th,
.k-grid td {
  padding: 4px 12px;
}

// .k-drawer-container {
//   position: fixed;
// }
