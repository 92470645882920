.input-field-login > .k-form-field {
  margin: 0;
}

.input-field-login > .k-form-field > .k-form-field-wrap {
}

.input-field-login > .k-form-field > .k-text-error {
  height: 1px;
}

.container-login {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container-login {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

.sign-in-form {
  align-items: center;
  justify-content: center;
  padding: 0rem 10rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  transform: translateY(100px) translateX(250px);
}

.sign-up-form {
  opacity: 0;
  z-index: 1;
}

.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field-login {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field-login i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field-login input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field-login input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn-login {
  width: 80px;
  text-align: center;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 30px 60px;
  cursor: pointer;
  transition: 0.5s;
}

.panels-container-login {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container-login:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(50%);
  background-image: linear-gradient(-45deg, #ff6358 0%, #ff635899 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
  animation-name: example;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes example {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(-50%);
  }
}

.panel-login {
  align-items: flex-end;
  justify-content: space-around;
  z-index: 6;
}

.left-panel-login {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.sign-in-form .content-login {
  color: #000;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
  border-bottom: 2px solid rgba(255, 0, 0, 0.1);
  margin-bottom: 10px;
}

.sign-in-form .content-login > h3 {
  font-size: 64px;
  text-align: center;
}

.sign-in-form .content-login > p {
  font-size: 24px;
}

.panel-login h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel-login p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn-login.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image-login,
.right-panel .content-login {
  transform: translateX(800px);
}

/* ANIMATION */

.container-login.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container-login.sign-up-mode .left-panel-login .image-login,
.container-login.sign-up-mode .left-panel-login .content-login {
  transform: translateX(-800px);
}

.container-login.sign-up-mode .signin-signup {
  left: 25%;
}

.container-login.sign-up-mode .sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container-login.sign-up-mode .sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container-login.sign-up-mode .right-panel .image-login,
.container-login.sign-up-mode .right-panel .content-login {
  transform: translateX(0%);
}

.container-login.sign-up-mode .left-panel-login {
  pointer-events: none;
}

.container-login.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container-login {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container-login.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container-login {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel-login {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel-login {
    grid-row: 1 / 2;
  }

  .image-login {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel-login .content-login {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel-login h3 {
    font-size: 1.2rem;
  }

  .panel-login p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn-login.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container-login:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container-login.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container-login.sign-up-mode .left-panel-login .image-login,
  .container-login.sign-up-mode .left-panel-login .content-login {
    transform: translateY(-300px);
  }

  .container-login.sign-up-mode .right-panel .image-login,
  .container-login.sign-up-mode .right-panel .content-login {
    transform: translateY(0px);
  }

  .right-panel .image-login,
  .right-panel .content-login {
    transform: translateY(300px);
  }

  .container-login.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  .sign-in-form {
    padding: 0 1.5rem;
  }

  .image-login {
    display: none;
  }
  .panel-login .content-login {
    padding: 0.5rem 1rem;
  }
  .container-login {
    padding: 1.5rem;
  }

  .container-login:before {
    bottom: 72%;
    left: 50%;
  }

  .container-login.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}

//ME classes

.LoginUsernameIcon {
  font-size: 20px !important;
  margin-right: 10px;
  margin-bottom: 5px !important;
  color: black;
}

.LoginPasswordIcon {
  font-size: 20px !important;
  margin-right: 10px;
  margin-bottom: 5px !important;
  color: black;
}

.LoginInput {
  background-color: gainsboro;
  border: 1px solid gainsboro;
  border-radius: 12px;
  padding: 5px;
  text-align: left;
}

.LoginInput::placeholder {
  color: #f9f4f4;
}

.Logintitle {
  color: black;
}

.sign-in-button {
  width: 100px;
  text-align: center;
  border: none;
  outline: none;
  height: 30px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: 0.5s;
}

.SignIn-Form {
  transform: translate(-50%, -50%);
  transition: 1s 0.7s ease-in-out;

  // transition-delay: 0.6s;
  z-index: 6;
  transform: translateY(400px) scale(1.3);
}

.LoginPageContainer:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 50%;
  transform: translateY(50%);
  background-image: linear-gradient(-45deg, #ff6358 0%, #ff635899 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;

  animation-name: example;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.LoginPageContainer {
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.SVGImageSpace {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;

  justify-content: center;
  display: flex;
  align-items: center;
}

.image-login {
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
  //transform: translateY(100px) scale(1.2) translateX(100px);
  transform: translateY(-100px) scale(0.7) translateX(-200px);
}
