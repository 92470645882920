.UserProfileRow {
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.ClockRow {
  background-color: #ff6358;
  color: white;
  padding: 20px;
  text-align: center;
}

.CloseRow {
  color: white;
  font-size: 10px;
  padding: 0px !important;
  background-color: #ff6358;
}

.UserRow {
  padding: 30px;
}

.IconsRow {
  padding: 30px;
  font-size: 20px;
}

.ProfileIconStyle {
  color: #ff6358;
}

.NotificationIconStyle {
  color: #06d6a0;
}

.LogoutIconStyle {
  color: #118ab2;
  transform: rotate(180deg);
}

.GlobeIconStyle {
  color: #ffd166;
}

.DateRow {
  font-size: 18px;
}

.NameRow {
  padding-left: 0 !important;
}

.AvatarSize {
  height: 120px;
  width: 100px;
}
