//remove the arrow up and down of numeric text box
.k-numerictextbox .k-select {
  display: none;
}

//dropdown background white
.k-dropdown .k-input {
  background-color: white !important;
}

//put the calendar and dropdowns in a dialog so they don't appear behind
.k-animation-container {
  z-index: 99999 !important;
}

.checkbox-margintop {
  margin-top: 2em !important;
}

//UPLOAD COMPONENT STATUS
.companyimage .k-upload-status {
  color: #06d6a0;
  font-size: 12px !important;
}

.companyimage .k-dropzone-hint {
  display: none;
}
